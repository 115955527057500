import request from '@/utils/request'

// 查询剧本配置（作者/监制/发行）列表
export function listScriptConfig(query) {
  return request({
    url: '/script/script/config/list',
    method: 'get',
    params: query
  })
}

export function loadOptionsByName(query) {
  return request({
    url: '/script/script/config/loadOptionsByName',
    method: 'get',
    params: query
  })
}

// 查询剧本配置（作者/监制/发行）详细
export function getScriptConfig(id) {
  return request({
    url: '/script/script/config/' + id,
    method: 'get'
  })
}

// 新增剧本配置（作者/监制/发行）
export function addScriptConfig(data) {
  return request({
    url: '/script/script/config',
    method: 'post',
    data: data
  })
}

// 修改剧本配置（作者/监制/发行）
export function updateScriptConfig(data) {
  return request({
    url: '/script/script/config',
    method: 'put',
    data: data
  })
}

// 删除剧本配置（作者/监制/发行）
export function delScriptConfig(id) {
  return request({
    url: '/script/script/config/' + id,
    method: 'delete'
  })
}
